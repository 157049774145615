const videoinstallationData = [
    {num: "2", title: "Dead Boys in Paradise, projection", date: "2024"},
    {num: "3", title: "Crocodiles on a Ceiling, video installation", date: "2024"},
    {num: "4", title: "The Moving Museum - arrivals and departures (redux)", date: "2023"},
    {num: "5", title: "The Moving Museum - arrivals and departures (long version essay-film)", date: "2022"},
    {num: "6", title: "Bye Bye Bühne (VR project for theater w Judith Rosmair)", date: "2021"},
    {num: "7", title: "Ghost Dance, video installation", date: "2020"},
    {num: "8", title: "Endlose Aussicht (theater production w Theresia Walser and Judith Rosmair)", date: "2020"},
    {num: "9", title: "Europa and Bull with Antelope Horns (w Judith Rosmair) (in progress)", date: "2019"},
    {num: "10", title: "Here History Began, video installation", date: "2019"},
    {num: "11", title: "Amo Speaks, video installation", date: "2019"},
    {num: "12", title: "Atlas Portraits, projection", date: "2019"},
    {num: "13", title: "Zar Possession, video installation", date: "2018"},
    {num: "14", title: "The Phi Phenomenon, video installation", date: "2018"},
    {num: "15", title: "Atlas Portraits (multi-media project)", date: "2017"},
    {num: "16", title: "Atlas Fractured, documenta14 (Kassel Version), video installation", date: "2017"},
    {num: "17", title: "Atlas Fractured, documenta14 (Athens Version), video installation", date: "2017"},
    {num: "18", title: "The Cinema of Med Hondo, video installation", date: "2017"},
    {num: "19", title: "Ruang Suara: Soundscapes, contemporary music from Indonesia (w Ensemble Modern)", date: "2015"},
    {num: "20", title: "The Slave Ship, video installation (also known as The Law of the Sea)", date: "2015"},
    {num: "21", title: "The Mystery of History and His Story in My Story (photographic installation)", date: "2015"},
    {num: "22", title: "The Moving Museum Part One, (I feel Love), projection", date: "2015"},
    {num: "23", title: "Anima Mundi, video installation", date: "2014"},
    {num: "24", title: "The Atom Bomb, essay-film", date: "2014"},
    {num: "25", title: "Kiss the Moment, video installation", date: "2013"},
    {num: "26", title: "The Yule Tree", date: "2013"},
    {num: "27", title: "Double Feature Picture Show", date: "2013"},
    {num: "28", title: "View from Bar Olympia, video installation", date: "2012"},
    {num: "29", title: "The Festival of Sacrifice, video installation", date: "2012"},
    {num: "30", title: "Veiled Woman on a Beachfront", date: "2011"},
    {num: "31", title: "Eyes", date: "2011"},
    {num: "32", title: "Circus Maximus (live performance w Alvin Curran)", date: "2011"},
    {num: "33", title: "Lightning Strikes", date: "2010"},
    {num: "34", title: "Roma (w Alvin Curran)", date: "2010"},
    {num: "35", title: "Roma Amor (w Peter Maxwell Davis)", date: "2010"},
    {num: "36", title: "The Return of the Axum Obelisk", date: "2009"},
    {num: "37", title: "Living Room Music (w Alvin Curran)", date: "2009"},
    {num: "38", title: "From This World to That which is to Come", date: "2009"},
    {num: "39", title: "Ars e Navis", date: "2009"},
    {num: "40", title: "Message in a Bottle (theater performance w Xlab Factory)", date: "2008"},
    {num: "41", title: "Five for Monk (for dance performance w Roberta Garrison)", date: "2008"},
    {num: "42", title: "K Live, (live performance w Soul Circus)", date: "2008"},
    {num: "43", title: "K Alma, video installation", date: "2007"},
    {num: "44", title: "K Kino, projection", date: "2007"},
    {num: "45", title: "I Racconti del Mandala (theater performance w Xlab Factory)", date: "2007"},
    {num: "46", title: "Cruelly Coy (video performance w Sabina Meyer)", date: "2007"},
    {num: "47", title: "Brave New World (second Smithsonian version)", date: "2006"},
    {num: "48", title: "An Extraordinary Event (essay-film)", date: "2006"},
    {num: "49", title: "Meditation Light, video installation", date: "2006"},
    {num: "50", title: "Un Parto Particolare (w Tim Berne)", date: "2006"},
    {num: "51", title: "Concerto per la Vita Nasciente, live projection (w orchestra Santa Cecilia)", date: "2006"},
    {num: "52", title: "Trip to Mount Zugualla, video and photo installation", date: "2006"},
    {num: "53", title: "The Creation Trilogy", date: "2005"},
    {num: "54", title: "Roma Caput Mundi", date: "2005"},
    {num: "55", title: "Terra e Musica, interactive DVD (w Suono Italiano per L’Europa and Peter Maxwell Davis)", date: "2005"},
    {num: "56", title: "Body and Soul V3", date: "2004"},
    {num: "57", title: "Blood, multi-media installation", date: "2003"},
    {num: "58", title: "Africa of the Four Rivers, documentary", date: "2003"},
    {num: "59", title: "Un Capodanno di Musica, documentary (w Suono Italiano per L’Europa, Peter Maxwell Davis)", date: "2003"},
    {num: "60", title: "Africanized", date: "2002"},
    {num: "61", title: "Per Quei Splendidi Anni 60 (for Plinio De Martiis)", date: "2002"},
    {num: "62", title: "Appunti per un Ritratto di Borromini, TV documentary (Francesco Borromini)", date: "2001"},
    {num: "63", title: "The Art Show, TV documentary (George Segal)", date: "2001"},
    {num: "64", title: "Senritsumirai, TV documentary (contemporary Japanese Art)", date: "2001"},
    {num: "65", title: "La Tigre e il Fiore di Loto, TV documentary (Tibetan carpets)", date: "2001"},
    {num: "66", title: "Ways to a Void, film", date: "2000"},
    {num: "67", title: "Dialogo con Yves Klein, TV documentary (Yves Klein)", date: "2000"},
    {num: "68", title: "Brave New World, video installation", date: "1999"},
    {num: "69", title: "Una Parabola a Venezia, TV documentary w Alan Jones (Venice Biennale)", date: "1999"},
    {num: "70", title: "The Kebra Nagast, TV documentary", date: "1998"},
    {num: "71", title: "Ethiopian Coca Cola, video installation", date: "1997"},
    {num: "72", title: "Pompei", date: "1997"},
    {num: "73", title: "Blood is Not Fresh Water, essay film", date: "1997"},
    {num: "74", title: "The Birth of Dionysus, video installation", date: "1996"},
    {num: "75", title: "Art is Easy", date: "1996"},
    {num: "76", title: "La Nascità di Dionisio, video installation", date: "1996"},
    {num: "77", title: "Body and Soul V.1", date: "1995"},
    {num: "77", title: "Tightrope Walker with Owl and Volcano, projection", date: "1995"},
    {num: "78", title: "Flying with Birds", date: "1995"},
    {num: "79", title: "Sogno di una Citta Ideale (Dream of an Ideal City), documentary (Fiumare of Antonio Presti)", date: "1995"},
    {num: "80", title: "Horses", date: "1994"},
    {num: "81", title: "Mass Memory", date: "1994"},
    {num: "82", title: "Uno a Me, una a Te, uno a Raffaello (film collaboration w Jon Jost)", date: "1994"},
    {num: "83", title: "Vestire il Silenzio (w Roberto Sgarlata)", date: "1993"},
    {num: "84", title: "Buddha, projection", date: "1993"},
    {num: "85", title: "Travelling Light, essay film (a portrait of Lindsay Kemp)", date: "1992"},
    {num: "86", title: "La Madonna delle Rose, video installation", date: "1990"},
    {num: "87", title: "La Madonna di Theo Eshetu", date: "1990"},
    {num: "88", title: "No no no sì sì sì (w Bruno Ceccobelli)", date: "1989"},
    {num: "89", title: "Nativity", date: "1989"},
    {num: "90", title: "Gentleman in War (video clip)", date: "1988"},
    {num: "91", title: "L’Altro Mondo (the Other World)", date: "1987"},
    {num: "92", title: "Seshoku (Contact), documentary", date: "1987"},
    {num: "93", title: "Till Death Us do Part, video installation", date: "1987"},
    {num: "94", title: "Questa è Vita (That’s Life)", date: "1986"},
    {num: "95", title: "Atlanta (video collaboration with Robert Ashley)", date: "1986"},
    {num: "96", title: "Back to Zero", date: "1985"},
    {num: "97", title: "My Better Half", date: "1984"},
    {num: "98", title: "Six Domestic Videos", date: "1984"},
    {num: "99", title: "Live Death, closed circuit live TV performance", date: "1984"},
    {num: "100", title: "My Better Half", date: "1984"},
    {num: "101", title: "Lampi sull’Acqua (artists portraits)", date: "1984"},
    {num: "102", title: "Another Time, Another Place", date: "1983"},
    {num: "103", title: "Rites of Passage", date: "1982"},
    {num: "104", title: "Thunder and Lightning", date: "1982"},
    {num: "105", title: "Tube Style (w Casbah Production)", date: "1982"},
    {num: "106", title: "The Portrait Part One", date: "1982"},
    {num: "107", title: "Roman Fondale", date: "1981"},
    {num: "108", title: "Axum, super 8 film", date: "1981"},
    {num: "109", title: "Hi I’m Roger (pop video for The Penguin Club)", date: "1980"},
  
];

export default videoinstallationData;
